<template>
  <div class="stockDetail">
    <!-- <Icon type="ios-redo" class="goback" @click="back" />-->
    <h1>{{ showData.zqjc }}({{ showData.zqdm }}){{ showData.gptitle }}资料</h1>
    <template v-if="showData.gptitle == '新股'">
      <div>
        <div class="table-title">基本资料</div>
        <cTableItem
          :titleArr="['证券代码', '证券简称']"
          :contentArr="[showData.zqdm, showData.zqjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['机构名称', '股票类别']"
          :contentArr="[showData.jgmc, showData.gplb]"
        ></cTableItem>
        <cTableItem
          :titleArr="['招股公告日期', '招股意向书网址']"
          :contentArr="[showData.zgggrq, showData.zgyxswz]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px">
        <div class="table-title">发行信息</div>
        <cTableItem
          :titleArr="['申购代码1', '申购简称1']"
          :contentArr="[showData.sgdm1, showData.sgjc1]"
        ></cTableItem>
        <cTableItem
          :titleArr="['申购代码2', '申购简称2']"
          :contentArr="[showData.sgdm2, showData.sgjc2]"
        ></cTableItem>
        <cTableItem
          :titleArr="['总发行数量(万股)', '币种']"
          :contentArr="[showData.zfxsl, showData.bz]"
        ></cTableItem>
        <cTableItem
          :titleArr="['每股面值(元)', '发行价格(元)']"
          :contentArr="[
            showData.mgmz ? showData.mgmz : '',
            showData.fxjg ? showData.fxjg : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行定价方式', '发行方式']"
          :contentArr="[showData.fxdjfs, showData.fxfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行对象', '发行地区']"
          :contentArr="[showData.fxdx, showData.fxdq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['预计募集资金(万元)', '实际募资总额(万元)']"
          :contentArr="[
            !!showData.yjmjzj ? `${showData.yjmjzj}` : '',
            !!showData.sjmzze ? `${showData.sjmzze}` : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['实际募资净额(万元)', '发行费用总额(万元)']"
          :contentArr="[
            !!showData.sjmzje ? showData.sjmzje : '',
            !!showData.fxfyze ? showData.fxfyze : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['每股发行费用(元)', '上网发行日期']"
          :contentArr="[showData.mgfxfy, showData.swfxrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['其他发行日期', '主要发起人']"
          :contentArr="[showData.qtfxrq, showData.zyfqr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['加权发行市盈率', '摊薄发行市盈率']"
          :contentArr="[showData.jqfxsyl, showData.tbfxsyl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行前每股净资产(元)', '发行后每股净资产(元)']"
          :contentArr="[
            !!showData.fxqmgjzc ? showData.fxqmgjzc : '',
            !!showData.fxhmgjzc ? showData.fxhmgjzc : '',
          ]"
        ></cTableItem>
      </div>
      <div class="more" @click="getMore" v-if="!isMore">点击展开更多</div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">申购状况</div>
        <cTableItem
          :titleArr="['网上有效申购股数(亿股)', '网上有效申购户数(户)']"
          :contentArr="[
            !!showData.wsyxsggs ? `${showData.wsyxsggs}` : '',
            !!showData.wsyxsghs ? `${showData.wsyxsghs}` : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上有效申购资金(亿元)', '二级配售有效申购股数(亿股)']"
          :contentArr="[
            !!showData.wsyxsgzj ? `${showData.wsyxsgzj}` : '',
            !!showData.ejpsyxsggs ? showData.ejpsyxsggs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['二级配售有效申购户数(户)', '二级配售有效申购资金(亿元)']"
          :contentArr="[
            !!showData.ejpsyxsghs ? showData.ejpsyxsghs : '',
            !!showData.ejpsyxsgzj ? showData.ejpsyxsgzj : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下有效申购股数(亿股)', '网下有效申购户数(户)']"
          :contentArr="[
            !!showData.wxyxsggs ? showData.wxyxsggs : '',
            !!showData.wxyxsghs ? showData.wxyxsghs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下有效申购资金(亿元)', '一般法人有效申购股数(亿股)']"
          :contentArr="[
            !!showData.wxyxsgzj ? `${showData.wxyxsgzj}` : '',
            !!showData.ybfryxsggs ? showData.ybfryxsggs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['一般法人获配数量(万股)', '一般法人获配户数(户)']"
          :contentArr="[
            !!showData.ybfrhpsl ? showData.ybfrhpsl : '',
            !!showData.ybfrhphs ? showData.ybfrhphs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['战略投资者有效申购股数', '战略投资者获配数量']"
          :contentArr="[showData.zltzzyxsggs, showData.zltzzhpsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['战略投资者获配户数(户)', '证券基金有效申购数量']"
          :contentArr="[
            !!showData.zltzzhphs ? showData.zltzzhphs : '',
            showData.zqjjyxsgsl,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['证券基金获配数量', '证券基金获配户数(户)']"
          :contentArr="[
            showData.zqjjhpsl,
            !!showData.zqjjhphs ? showData.zqjjhphs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['申购价格范围', '网下申购上限']"
          :contentArr="[showData.sgjgfw, showData.wxsgsx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下申购下限', '网上申购上限']"
          :contentArr="[showData.wxsgxx, showData.wssgsx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上申购下限', '网上申购单位(股)']"
          :contentArr="[
            showData.wssgxx,
            !!showData.wssgdw ? showData.wssgdw : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下申购单位(股)', '网下申购截止日']"
          :contentArr="[
            !!showData.wxsgdw ? showData.wxsgdw : '',
            showData.wxsgjzr,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下申购剔除比例', '网下申购剔除价格下限']"
          :contentArr="[showData.wxsgtcbl, showData.wxsgtcjgxx]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">中签情况</div>
        <cTableItem
          :titleArr="['中签率公告日', '网下配售中签率']"
          :contentArr="[showData.zqlggr, showData.wxpszql]"
        ></cTableItem>
        <cTableItem
          :titleArr="['上网发行中签率', '二级市场配售发行中签率']"
          :contentArr="[showData.swfxzql, showData.ejscpsfxzql]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">承销情况</div>
        <cTableItem
          :titleArr="['承销方式', '承销费用']"
          :contentArr="[showData.cxfs, showData.cxfy]"
        ></cTableItem>
        <cTableItem
          :titleArr="['承销余额', '']"
          :contentArr="[showData.cxye, '']"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">重要日期</div>
        <cTableItem
          :titleArr="['市值计算日', '二级市场配售日期']"
          :contentArr="[showData.scjsr, showData.ejscpsrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下配售起始日', '网下配售截止日']"
          :contentArr="[showData.wxpsqsr, showData.wxpsjzr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['上市公告日期', '资金到帐日']"
          :contentArr="[showData.ssggrq, showData.zjdzr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['现场推介起始日', '现场推介截止日']"
          :contentArr="[showData.xctjqsr, showData.xctjjzr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上路演起始日', '初步询价开始日期']"
          :contentArr="[showData.wslyqsr, showData.cbxjksrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['初步询价截止日期', '发行价格区间公告日期']"
          :contentArr="[showData.cbxjjzrq, showData.fxjgqjggrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下冻结资金返还日期', '网上冻结资金返还日期']"
          :contentArr="[showData.wxdjzjfhrq, showData.wsdjzjfhrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['定价公告日期', '摇号结果公告日']"
          :contentArr="[showData.djggrq, showData.yhjgggr]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px; margin-bottom: 100px" v-if="isMore">
        <div class="table-title">其他信息</div>
        <cTableItem
          :titleArr="['公开发行数量', '预计发行股数(万股)']"
          :contentArr="[
            showData.gkfxsl,
            !!showData.yjfxsl ? showData.yjfxsl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['其中：国有股减持数量', '网上预设发行数量比例(%)']"
          :contentArr="[
            showData.qzgygjcsl,
            !!showData.wsysfxslbl ? showData.wsysfxslbl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['定向募集法人股', '网下预设发行数量比例(%)']"
          :contentArr="[
            showData.dxmzfrg,
            !!showData.wxysfxslbl ? showData.wxysfxslbl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['定向募集职工股', '网上预设发行数量']"
          :contentArr="[showData.dxmjzgg, showData.wsysfxsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['其他发行数量', '网下预设发行数量']"
          :contentArr="[showData.qtfxsl, showData.wxysfxsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['询价上限', '网下超额认购倍数']"
          :contentArr="[showData.xjsx, showData.wxcergbs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['询价下限', '战略投资者发行量比例']"
          :contentArr="[showData.xjxx, showData.zltzzfxlbl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['分配承诺', '是否行使超额配售权']"
          :contentArr="[showData.fpcn, showData.sfxscepsq == 1 ? '是' : '否']"
        ></cTableItem>
        <cTableItem
          :titleArr="['上网发行数量', '超额配售数量']"
          :contentArr="[showData.swfxsl, showData.cepssl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['二级市场配售数量', '初步询价超额认购倍数']"
          :contentArr="[showData.ejscpssl, showData.cbxjcergbs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下配售数量', '预计新增发行数量上限']"
          :contentArr="[showData.wxpssl, showData.yjxzfxslsx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['回拨数量', '预计老股东转让数量上限']"
          :contentArr="[showData.hbsl, showData.yjlgdzrslsx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['回拨方式', '预计战略配售数量上限']"
          :contentArr="[showData.hbfs, showData.yjzlpsslsx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['超额认购倍数', '战略投资者配售数量']"
          :contentArr="[showData.cergbs, showData.zltzzpssl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['战略投资者锁定期(月)', '老股东转让数量']"
          :contentArr="[
            !!showData.zltzzsdq ? showData.zltzzsdq : '',
            showData.lgdzrsl,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['是否累计询价', '老股东转让发行费用']"
          :contentArr="[showData.sfljxj == 1 ? '是' : '否', showData.lgdzrfxfy]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下投资者锁定期(月)', 'IPO阶段进展状态']"
          :contentArr="[
            !!showData.wxtzzsdq ? showData.wxtzzsdq : '',
            showData.ipojdjzzt,
          ]"
        ></cTableItem>
      </div>
    </template>
    <template v-else-if="showData.gptitle == '增发'">
      <div>
        <div class="table-title">基本资料</div>
        <cTableItem
          :titleArr="['证券代码', '证券简称']"
          :contentArr="[showData.zqdm, showData.zqjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['机构名称', '股票类别']"
          :contentArr="[showData.jgmc, showData.gplb]"
        ></cTableItem>
        <cTableItem
          :titleArr="['招股意向书网址', '']"
          :contentArr="[showData.zgggrq, '']"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px">
        <div class="table-title">增发信息</div>
        <cTableItem
          :titleArr="['申购代码1', '申购简称1']"
          :contentArr="[showData.sgdm1, showData.sgjc1]"
        ></cTableItem>
        <cTableItem
          :titleArr="['申购代码2', '申购简称2']"
          :contentArr="[showData.sgdm2, showData.sgjc2]"
        ></cTableItem>
        <cTableItem
          :titleArr="['公告日期', '']"
          :contentArr="[showData.ggrq,'']"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上发行日期', '其他发行日期']"
          :contentArr="[showData.wsfxrq, showData.qtfxrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行定价方式', '发行地区']"
          :contentArr="[showData.fxdjfs, showData.fxdq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['币种', '发行价格(元)']"
          :contentArr="[showData.bz, !!showData.fxjg ? showData.fxjg : '']"
        ></cTableItem>
        <cTableItem
          :titleArr="['每股面值(元)', '发行后每股收益(元)']"
          :contentArr="[
            !!showData.mgmz ? showData.mgmz : '',
            !!showData.fxhmgsy ? showData.fxhmgsy : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行方式', '发行对象']"
          :contentArr="[showData.fxfs, showData.fxdx]"
        ></cTableItem>
        <cTableItem
          :titleArr="['总发行数量(万股)', '公开发行数量(万股)']"
          :contentArr="[
            !!showData.zfxsl ? showData.zfxsl : '',
            !!showData.gkfxsl ? showData.gkfxsl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['预计募资金额(万元)', '实际募资总额(万元)']"
          :contentArr="[
            !!showData.yjmzje ? showData.yjmzje : '',
            !!showData.sjmzze ? showData.sjmzze : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['其中现金(万元)', '实际募资净额(万元)']"
          :contentArr="[
            showData.qzxj,
            !!showData.sjmzje ? showData.sjmzje : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行费用总额(万元)', '每股发行费用(元)']"
          :contentArr="[
            !!showData.fxfyze ? showData.fxfyze : '',
            showData.mgfxfy,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行前总股本(万股)', '发行后总股本(万股)']"
          :contentArr="[showData.fxqzgb, showData.fxhzgb]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行前流通股本(万股)', '发行后流通股本(万股)']"
          :contentArr="[showData.fxqltgb, showData.fxhltgb]"
        ></cTableItem>
        <cTableItem
          :titleArr="['加权发行市盈率', '摊薄发行市盈率']"
          :contentArr="[showData.jqfxsyl, showData.tbfxsyl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['发行前每股净资产', '发行后每股净资产']"
          :contentArr="[showData.fxqmgjzc, showData.fxhmgjzc]"
        ></cTableItem>
      </div>
      <div class="more" @click="getMore" v-if="!isMore">点击展开更多</div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">申购状况</div>
        <cTableItem
          :titleArr="['网上有效申购股数(亿股)', '网上有效申购资金(亿元)']"
          :contentArr="[showData.wsyxsggs, showData.wsyxsgzj]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上有效申购户数(户)', '老股东有效申购股数（亿股）']"
          :contentArr="[
            !!showData.wsyxsghs ? showData.wsyxsghs : '',
            showData.lgdyxsggs,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['老股东有效申购户数(户)', '老股东有效申购资金（亿元）']"
          :contentArr="[
            !!showData.lgdyxsghs ? showData.lgdyxsghs : '',
            showData.lgdyxsgzj,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下有效申购户数(户)', '网下有效申购股数']"
          :contentArr="[
            !!showData.wxyxsghs ? showData.wxyxsghs : '',
            showData.wxyxsggs,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下有效申购资金（亿元）', '一般法人申购数量']"
          :contentArr="[showData.wxyxsgzj, showData.ybfrsgsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['一般法人配售数量', '一般法人配售户数(户)']"
          :contentArr="[
            showData.ybfrpssl,
            !!showData.ybfrpshs ? showData.ybfrpshs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['证券基金申购数量(万股)', '证券基金配售数量(万股)']"
          :contentArr="[
            !!showData.zqjjsgsl ? showData.zqjjsgsl : '',
            !!showData.zqjjpssl ? showData.zqjjpssl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['证券基金配售户数(户)', '战略投资者申购数量(万股)']"
          :contentArr="[
            !!showData.zqjjpshs ? showData.zqjjpshs : '',
            !!showData.zltzzsgsl ? showData.zltzzsgsl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['战略投资者配售数量(万股)', '战略投资者配售户数(户)']"
          :contentArr="[
            !!showData.zltzzpssl ? showData.zltzzpssl : '',
            !!showData.zltzzpshs ? showData.zltzzpshs : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['原股东优先申购日', '网下申购数量上限(万股)']"
          :contentArr="[
            showData.ygdyxsgr,
            !!showData.wxsgslsx ? showData.wxsgslsx : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下申购数量下限(股)', '网下申购定金']"
          :contentArr="[
            !!showData.wxsgslxx ? showData.wxsgslxx : '',
            showData.wxsgdj,
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="[
            'A类投资者网下申购单位(万股)',
            'B类投资者网下申购单位(万股)',
          ]"
          :contentArr="[
            !!showData.altzzwxsgdw ? showData.altzzwxsgdw : '',
            !!showData.bltzzwxsgdw ? showData.bltzzwxsgdw : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上申购上限(股)', '网上申购数量下限']"
          :contentArr="[
            showData.wssgsx,
            !!showData.wssgslxx ? showData.wssgslxx : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上申购单位(股)', '网下申购定金到帐日']"
          :contentArr="[
            !!showData.wssgdw ? showData.wssgdw : '',
            showData.wxsgdjdzr,
          ]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">中签情况</div>
        <cTableItem
          :titleArr="['网上发行中签率', '网下发行中签率']"
          :contentArr="[showData.wsfxzql, showData.wxfxzql]"
        ></cTableItem>
        <cTableItem
          :titleArr="['A类投资者网下申购中签比例', 'B类投资者网下申购中签比例']"
          :contentArr="[showData.altzzwxsgzqbl, showData.bltzzwxsgzqbl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上中签结果公告日', '网下中签率公布及退款日']"
          :contentArr="[showData.wszqjgggr, showData.wxzqlgbjtkr]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">承销情况</div>
        <cTableItem
          :titleArr="['承销方式', '承销费用']"
          :contentArr="[showData.cxfs, showData.cxfy]"
        ></cTableItem>
        <cTableItem
          :titleArr="['承销余额', '']"
          :contentArr="[showData.cxye, '']"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">重要日期</div>
        <cTableItem
          :titleArr="['股权登记日', '除权日']"
          :contentArr="[showData.gqdjr, showData.cqr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下询价配售开始日', '网下询价配售结束日']"
          :contentArr="[showData.wxxjpsksr, showData.wxxjpsjsr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['上市公告日期', '增发股上市日']"
          :contentArr="[showData.ssggrq, showData.zfgssr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['资金到帐日', 'A类锁定期(月)']"
          :contentArr="[showData.zjdzr, !!showData.alsdq ? showData.alsdq : '']"
        ></cTableItem>
        <cTableItem
          :titleArr="['网下资金退缴日', '网上资金退款日']"
          :contentArr="[showData.wxzjtjr, showData.wszjtkr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['停牌截止日期', '停牌起始日期']"
          :contentArr="[showData.tpjzrq, showData.tpqsrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上路演起始日', '']"
          :contentArr="[showData.wslyqsr, '']"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px; margin-bottom: 100px" v-if="isMore">
        <div class="table-title">其他信息</div>
        <cTableItem
          :titleArr="['竞价上限(元)', '竞价下限(元)']"
          :contentArr="[
            !!showData.jjsx ? showData.jjsx : '',
            !!showData.jjxx ? showData.jjxx : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['老股东配售方式(元)', '老股东配售比例(股)']"
          :contentArr="[
            !!showData.lgdpsfs ? showData.lgdpsfs : '',
            !!showData.lgdpsbl ? showData.lgdpsbl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['老股东配售价格', '分配承诺']"
          :contentArr="[showData.lgdpsjg, showData.fpcn]"
        ></cTableItem>
        <cTableItem
          :titleArr="['是否除权', '老股东配售数量']"
          :contentArr="[showData.sfcq == 1 ? '是' : '否', showData.lgdpssl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['起额认购倍数', '网下配售数量']"
          :contentArr="[showData.qergbs, showData.wxpssl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['网上发行数量', '回拨数量']"
          :contentArr="[showData.wsfxsl, showData.hbsl]"
        ></cTableItem>

        <cTableItem
          :titleArr="['预计发行股数(万股)', '网上预设发行数量比例(%)']"
          :contentArr="[
            !!showData.yjfxgs ? showData.yjfxgs : '',
            !!showData.wsysfxslbl ? showData.wsysfxslbl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="[
            '网下预设发行数量比例(%)',
            '原股东最多认购数占发行数比例(%)',
          ]"
          :contentArr="[
            !!showData.wxysfxslbl ? showData.wxysfxslbl : '',
            !!showData.ygdzdrgszfxsbl ? showData.ygdzdrgszfxsbl : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['A类投资者认购下限(万股)', 'B类投资者认购下限(万股)']"
          :contentArr="[
            !!showData.altzzrgxx ? showData.altzzrgxx : '',
            !!showData.bltzzrgxx ? showData.bltzzrgxx : '',
          ]"
        ></cTableItem>
         <cTableItem
          :titleArr="['回拨方式', '本次上市的无流通限制及锁定安排的股份']"
          :contentArr="[showData.hbfs, !!showData.bcssdwltxzjsdapdgf ? showData.bcssdwltxzjsdapdgf : '']"
        ></cTableItem>
        <!-- <cTableItem
          :titleArr="['本次上市的无流通限制及锁定安排的股份(万股)', '']"
          :contentArr="[
            !!showData.bcssdwltxzjsdapdgf ? showData.bcssdwltxzjsdapdgf : '',
            '',
          ]"
        ></cTableItem> -->
      </div>
    </template>
    <template v-else-if="showData.gptitle == '配股'">
      <div>
        <div class="table-title">基本资料</div>
        <cTableItem
          :titleArr="['证券代码', '证券简称']"
          :contentArr="[showData.zqdm, showData.zqjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['机构名称', '股票类别']"
          :contentArr="[showData.jgmc, showData.gplb]"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px">
        <div class="table-title">配股信息</div>
        <cTableItem
          :titleArr="['公众配售代码', '公众配售简称']"
          :contentArr="[showData.gzpsdm, showData.gzpsjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['其他配售代码', '其他配售简称']"
          :contentArr="[showData.qtpsdm, showData.qtpsjc]"
        ></cTableItem>
        <cTableItem
          :titleArr="['公告日期', '发行方式']"
          :contentArr="[showData.ggrq, showData.fxfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['配售对象', '配股比例']"
          :contentArr="[showData.psdx, showData.pgbl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['配股价格(元)', '预计配股数量(万股)']"
          :contentArr="[showData.pgjg, showData.yjpgsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['预计募集资金(万元)', '实际募资总额(万元)']"
          :contentArr="[
            !!showData.yjmjzj ? showData.yjmjzj : '',
            !!showData.sjmzze ? showData.sjmzze : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['预计发行费用(万元)', '发行费用总额(万元)']"
          :contentArr="[
            !!showData.yjfxfy ? showData.yjfxfy : '',
            !!showData.fxfyze ? showData.fxfyze : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['实际配股数量(万股)', '实际募资净额(万元)']"
          :contentArr="[
            showData.sjpgsl,
            !!showData.sjmzje ? showData.sjmzje : '',
          ]"
        ></cTableItem>
        <cTableItem
          :titleArr="['配股前总股本(万股)', '配股后总股本(万股)']"
          :contentArr="[showData.pgqzgb, showData.pghzgb]"
        ></cTableItem>
        <cTableItem
          :titleArr="['配股前流通股本(万股)', '配股后流通股本(万股)']"
          :contentArr="[showData.pgqltgb, showData.pghltgb]"
        ></cTableItem>
        <cTableItem
          :titleArr="['每股配权转让费', '委托单位']"
          :contentArr="[showData.mgpqzrf, showData.wtdw]"
        ></cTableItem>
      </div>
      <div class="more" @click="getMore" v-if="!isMore">点击展开更多</div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">承销情况</div>
        <cTableItem
          :titleArr="['承销方式', '承销费用']"
          :contentArr="[showData.cxfs, showData.cxfy]"
        ></cTableItem>
        <cTableItem
          :titleArr="['承销余额', '']"
          :contentArr="[showData.cxye, '']"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px" v-if="isMore">
        <div class="table-title">重要日期</div>
        <cTableItem
          :titleArr="['配股缴款起始日', '配股缴款截止日']"
          :contentArr="[showData.pgjkqsr, showData.pgjkjzr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['配股权证交易起始日', '配股权证交易截止日']"
          :contentArr="[showData.pgzqjyqsr, showData.pgzqjyjzr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['配股发行结果公告日', '资金到账日']"
          :contentArr="[showData.pgfxjgggr, showData.zjdzr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['上市公告日期', '配股上市日']"
          :contentArr="[showData.ssggrq, showData.pgssr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['股权登记日', '配股失败，退还申购款日期']"
          :contentArr="[showData.gqdjr, showData.thsgkrq]"
        ></cTableItem>
        <cTableItem
          :titleArr="['除权基准日', '停牌起始日']"
          :contentArr="[showData.cqjzr, showData.tpqsr]"
        ></cTableItem>
        <cTableItem
          :titleArr="['停牌截止日', '']"
          :contentArr="[showData.tpjzr, '']"
        ></cTableItem>
      </div>
      <div style="margin-top: 20px; margin-bottom: 100px" v-if="isMore">
        <div class="table-title">其他信息</div>
        <cTableItem
          :titleArr="['国家股实配数量', '公众股实配数量']"
          :contentArr="[showData.gjgspsl, showData.gzgspsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['法人股实配数量', '大股东认购数量']"
          :contentArr="[showData.frgspsl, showData.dgdrgsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['职工股实配数量', '大股东认购方式']"
          :contentArr="[showData.zggspsl, showData.dgdrgfs]"
        ></cTableItem>
        <cTableItem
          :titleArr="['转配股实配数量', '可转配股数量']"
          :contentArr="[showData.zpgspsl, showData.kzpgsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['其他股份实配数量', '法人获转配数量']"
          :contentArr="[showData.qtgfspsl, showData.frhzpsl]"
        ></cTableItem>
        <cTableItem
          :titleArr="['公众获转配数量', '']"
          :contentArr="[showData.gzhzpsl, '']"
        ></cTableItem>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cTableItem from "@/components/cTableItem";
import { returnFloat, formatDate } from "@/utils";

export default {
  name: "stock",
  data() {
    return {
      isMore: false,
      showData: {},
    };
  },
  computed: {
    ...mapState(["activeDetail"]),
  },
  components: {
    cTableItem,
  },
  mounted() {
    this.showData = JSON.parse(JSON.stringify(this.activeDetail));
    //处理数据格式

    //数据格式为整数
    let arrTo0 = [
      "wsyxsghs",
      "lgdyxsghs",
      "wxyxsghs",
      "ybfrpshs",
      "zqjjpshs",
      "zltzzpshs",
    ];
    //数据格式为保留两位小数
    let arrTo2 = [
      "zfxsl",
      "mgmz",
      "mgfxfy",
      "wsyxsggs",
      "wsyxsgzj",
      "ejpsyxsggs",
      "ejpsyxsgzj",
      "wxyxsggs",
      "wxyxsgzj",
      "ybfryxsggs",
      "ybfrhpsl",
      "zltzzyxsggs",
      "zltzzhpsl",
      "zltzzhphs",
      "zqjjhpsl",
      "wxsgsx",
      "wxsgxx",
      "wssgsx",
      "wssgxx",
      "wssgdw",
      "wxsgdw",
      "wxsgtcbl",
      "wxsgtcjgxx",
      "gkfxsl",
      "yjfxgs",
      "qzgygjcsl",
      "dxmzfrg",
      "dxmjzgg",
      "wsysfxslbl",
      "wxysfxslbl",
      "wsysfxsl",
      "wxysfxsl",
      "qtfxsl",
      "xjsx",
      "xjxx",
      "wxcergbs",
      "zltzzfxlbl",
      "swfxsl",
      "cepssl",
      "ejscpssl",
      "cbxjcergbs",
      "wxpssl",
      "yjxzfxslsx",
      "yjlgdzrslsx",
      "hbsl",
      "yjzlpsslsx",
      "cergbs",
      "zltzzpssl",
      "lgdzrfxfy",
      "lgdzrsl",
      "mgmz",
      "fxhmgsy",
      "zfxsl",
      "gkfxsl",
      "yjmzje",
      "sjmzze",
      "qzxj",
      "sjmzje",
      "fxfyze",
      "mgfxfy",
      "fxqzgb",
      "fxhzgb",
      "fxqltgb",
      "fxhzgb",
      "jqfxsyl",
      "tbfxsyl",
      "fxqmgjzc",
      "fxhmgjzc",
      "wsyxsggs",
      "wsyxsgzj",
      "lgdyxsgzj",
      "wxyxsggs",
      "wxyxsgzj",
      "ybfrpssl",
      "zqjjsgsl",
      "zqjjpssl",
      "zltzzsgsl",
      "zltzzpssl",
      "wxsgslsx",
      "wxsgslxx",
      "wxsgdj",
      "altzzwxsgdw",
      "bltzzwxsgdw",
      "wssgdw",
      "wsfxzql",
      "wxfxzql",
      "altzzwxsgzqbl",
      "bltzzwxsgzqbl",
      "cxfy",
      "cxye",
      "jjsx",
      "jjxx",
      "lgdpsjg",
      "lgdpsbl",
      "lgdpssl",
      "qergbs",
      "wsfxsl",
      "wxpssl",
      "hbsl",
      "yjfxgs",
      "wsysfxslbl",
      "wxysfxslbl",
      "ygdzdrgszfxsbl",
      "altzzrgxx",
      "bltzzrgxx",
      "bcssdwltxzjsdapdgf",
      "pgbl",
      "pgjg",
      "yjpgsl",
      "yjmjzj",
      "sjmzze",
      "yjfxfy",
      "fxfyze",
      "sjpgsl",
      "sjmzje",
      "pgqzgb",
      "pghzgb",
      "pgqltgb",
      "pghltgb",
      "mgpqzrf",
      "cxye",
      "cxfy",
      "gjgspsl",
      "gzgspsl",
      "frgspsl",
      "dgdrgsl",
      "zggspsl",
      "zpgspsl",
      "qtgfspsl",
      "frhzpsl",
      "gzhzpsl",
    ];
    //数据格式为添加%符号
    let arrTo100 = ["wxpszql", "swfxzql", "ejscpsfxzql"];
    let rq = [
      "zgggrq",
      "qtfxrq",
      "ejscpsrq",
      "ssggrq",
      "cbxjksrq",
      "cbxjjzrq",
      "fxjgqjggrq",
      "wxdjzjfhrq",
      "wsdjzjfhrq",
      "djggrq",
      "ggrq",
      "wsfxrq",
      "qtfxrq",
      "tpjzrq",
      "tpqsrq",
      "thsgkrq",
    ];

    arrTo100.forEach((data) => {
      "";
      if (!isNaN(this.showData[data]) && this.showData[data]) {
        this.showData[data] =
          Number(this.showData[data] * 100).toFixed(2) + "%";
      }
    });
    arrTo0.forEach((data) => {
      if (!isNaN(this.showData[data]) && this.showData[data]) {
        this.showData[data] = returnFloat(this.showData[data], 0);
      }
    });
    arrTo2.forEach((data) => {
      if (!isNaN(this.showData[data]) && this.showData[data] != '0' && this.showData[data]) {
          this.showData[data] = returnFloat(this.showData[data], 2);
        }else{
          this.showData[data] = '-'
        }

    });
    rq.forEach((data) => {
      if (this.showData[data]) {
        this.showData[data] = formatDate(this.showData[data]);
      }
    });
  },
  methods: {
    getMore() {
      this.isMore = true;
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.stockDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;

  .goback {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #00b6ff;
    font-size: 36px;
    transform: rotateY(180deg);
    cursor: pointer;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 26px;
    margin-bottom: 20px;
  }

  div.more {
    height: 40px;
    background: rgba(250, 250, 253, 1);
    color: #00b6ff;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }

  div {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);

    div.table-title {
      @include flex(flex-start);
      padding-left: 20px;
      background: rgba(231, 240, 252, 1);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(214, 215, 217, 1);
    }

    div {
      @include flex;
      height: 40px;
      line-height: 40px;

      .line-title:nth-of-type(1) {
        border-left: 1px solid rgba(214, 215, 217, 1);
      }

      .line-title {
        background: rgba(250, 250, 253, 1);
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }

      .line-content {
        background: #fff;
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>
